import React, { Component, createRef } from 'react';
import  { createPortal } from 'react-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ariaRole } from 'components/util/aria';
import { IconTopicGray, TcIconEmoji, TcIconKeyboard, TcIconTopic } from 'components/IconSystem';
import Tag from 'components/tag';
import { EmojiKeyboard } from 'components/emoji';
import { TopicMobilePost } from 'components/topic-mobile-post';
import { Mask } from 'components/mask';
import { Editor, insertHtml } from 'components/editor';
import { getProductId, sessionStorage } from 'components/util';
import { getApiV1ProductsAttribute } from 'components/api';
import { revertBr, revertEmojiTextToImage, revertWordToImage } from 'components/render-rich-text';
import { ATTRIBUTE_TYPE, IMAGE_UPLOAD_TYPE, ATTRIBUTE_STATUS } from 'components/constants/app';
import { ImagePreviewContainer } from './image-preview-container';
import { checkSize, checkType, IMG_ERR_COUNT, IMG_ERR_SIZE, IMG_ERR_TYPE, IMG_ERR_EXIST } from './schema';
import { BtnImageSelect } from './btn-image-select';
import { getValue } from './utils';
import { Topic } from 'components/topic';

import './message-bar.less';
import EmptyTopic from '../topic/empty-topic';
import Route from 'components/util/route';
import ConfirmDialog from '../../scenes/embed__new-post/page/confirm-dialog';

class MessageBar extends Component {
  static backspace(value) {
    const div = document.createElement('div');

    div.innerHTML = value;

    if (!div.lastChild) {
      return value;
    }

    let res = value;

    switch (div.lastChild.nodeType) {
    case Node.ELEMENT_NODE:
      div.lastChild.remove();
      res = div.innerHTML;
      break;
    case Node.TEXT_NODE:
      res = value.substring(0, value.length - 1);
      break;
    default:
    }

    return res;
  }

  constructor(props) {
    super(props);

    this.formEle = createRef();

    this.editor = createRef();
    this.reset = this.reset.bind(this);
    this.productId = getProductId();
    this.submit = this.submit.bind(this);
    this.getTopicDetails = this.getTopicDetails.bind(this);
    this.cancelTopicDetails = this.cancelTopicDetails.bind(this);
    this.toggleFoldTopic = this.toggleFoldTopic.bind(this);
    this.toggleFoldEmoji = this.toggleFoldEmoji.bind(this);
    this.getButtonDisabled = this.getButtonDisabled.bind(this);

    this.state = {
      is_fold_emoji: true,
      isFoldTopic: true,
      // 默认不打开图片上传， 需要等接口返回确认产品开启了图片上传后
      showUploadImg: false,
      images: props.defaultImage,
      topic: {},
      showConfirmDialog: false, // 是否显示取消的确认弹窗
    };
  }

  componentDidMount() {
    this.initUploadImgState();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { props, state } = this;

    // 关闭输入界面的同时，清空输入界面的状态
    if (prevProps.active !== 0 && props.active === 0) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ is_fold_emoji: true });

      return;
    }

    // 快速拉起表情键盘
    if (prevProps.active === 0 && props.active === 2) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ is_fold_emoji: false });
    }

    // 正常拉起键盘，获取焦点
    if (prevProps.active === 0 && props.active === 1 && this.editor.current) {
      this.editor.current.focus();
    }
  }

  initUploadImgState() {
    getApiV1ProductsAttribute({ productId: this.productId, type: ATTRIBUTE_TYPE.UPLOAD_IMG })
      .then((res) => {
        if (res.data !== ATTRIBUTE_STATUS.ON) return;
        this.setState({ showUploadImg: true });
      });
  }

  /**
     * 折叠 emoji 键盘区域
     * */
  toggleFoldEmoji() {
    this.setState((preState) => {
      if (preState.is_fold_emoji) {
        // 打开 emoji 键盘的同时，隐藏系统自带键盘
        this.editor.current.blur();
      } else {
        // 收起 emoji 键盘的同时，拉起自带键盘
        this.editor.current.focus();
      }

      return { is_fold_emoji: !preState.is_fold_emoji };
    });
  }

  /**
     * 折叠 话题 区域
     * */
  toggleFoldTopic() {
    this.setState({ isFoldTopic: !this.state.isFoldTopic });
  }

  getTopicDetails(item) {
    this.setState({ topic: item });
    this.props.checkedTopic(item.id);
    sessionStorage.setItem('checkTopicId', item.id);
  }

  cancelTopicDetails() {
    this.setState({ topic: {} });
    this.props.checkedTopic(null);
    sessionStorage.removeItem('checkTopicId');
  }

  /**
     * 添加表情
     * */
  insertEmoji(value) {
    if (!this.editor.current) {
      console.log('! Editor Ref 不存在');
      return;
    }
    const img = revertEmojiTextToImage(value);
    this.editor.current.UNSAFE_appendImage(img);
  }

  /**
     * 选择图片
     * 当发生异常的时候，会触发 props.onError
     * */
  selectImages(event) {
    console.log('input change:');

    const { state, props } = this;

    const images = Array.from(event.target.files);

    const existImageLength = Object.keys(state.images).length;

    if (images.length + existImageLength > props.limitImage) {
      return props.onError(IMG_ERR_COUNT);
    }

    if (images.some(checkSize)) {
      return props.onError(IMG_ERR_SIZE);
    }

    if (images.some(checkType)) {
      return props.onError(IMG_ERR_TYPE);
    }

    images.forEach((image) => {
      this.uploadImagePipe(image);
    });

    // 每次 input 读取完数据后，重置 form 表单
    // 否则 input 的 onChange 有时会不触发
    this.formEle.current.reset();
  }

  /**
     * 读取图片并预览出来
     * */
  readImage(image, imageId) {
    const { state, props } = this;

    console.log('before FileReader');

    const fr = new FileReader();

    const onLoad = (event) => {
      console.log('FileReader load:', event);

      const dataURL = event.target.result;

      this.setState(({ images }) => {
        images[imageId] = {
          id: imageId,
          progress: 0,
          dataURL,
        };
        return { images: Object.assign({}, images) };
      });
    };

    fr.addEventListener('load', onLoad, false);
    fr.addEventListener('error', event => console.log('fr error:', event), false);
    fr.addEventListener('loadend', event => console.log('fr loadend:', event), false);

    fr.readAsDataURL(image);

    console.log('after FileReader readAsDataURL');
  }

  /**
     * 上传图片并更新上传进度
     * 调用外部的实际接口，接口通过 props.onUploadImage 传递
     * */
  uploadImage(image, imageId) {
    const { state, props } = this;

    const fd = new FormData();

    fd.append('type', props.type);
    fd.append('upload', image);

    // 更新上传进度
    const onProgress = (progressEvent) => {
      const { total, loaded } = progressEvent;

      this.setState(({ images }) => {
        // 如果图片在上传过程被删了，不再更新进度
        if (typeof images[imageId] === 'undefined') {
          return;
        }

        let progress = ((loaded / total) * 100).toFixed(2);

        // 进度 100%，只表示上传完成了
        // 但此时还是收到后端但返回，所以进度要锁定在90%的值，便于视觉区分
        if (Number(progress) > 90) {
          progress = '90';
        }

        images[imageId] = Object.assign(images[imageId], { progress });

        return { images: Object.assign({}, images) };
      });
    };

    // 上传成功
    const onSuccess = (resp) => {
      if (resp.status !== 0) {
        return props.onError(new Error(resp.message));
      }

      this.setState(({ images }) => {
        // 如果图片在上传过程被删了，不再更新进度
        if (typeof images[imageId] === 'undefined') {
          return;
        }

        images[imageId] = Object.assign(images[imageId], {
          respInfo: resp.data,
          progress: '100',
        });

        return { images: Object.assign({}, images) };
      });
    };

    const onFail = (error) => {
      this.delImage(imageId);
      return props.onError(error);
    };

    props.onUploadImage(fd, onProgress).then(onSuccess)
      .catch(onFail);
  }

  /**
     * 删除指定图片
     * */
  delImage(targetId) {
    const { props } = this;

    this.setState(({ images }) => {
      delete images[targetId];
      return { images: Object.assign({}, images) };
    });
  }


  uploadImagePipe(image) {
    if (!image) {
      return;
    }

    if (Object.keys(this.state.images).length >= this.props.limitImage) {
      return this.props.onError(IMG_ERR_COUNT);
    }

    const { name, lastModified, size } = image;
    // 使用文件名、修改时间、尺寸 三元组作为缓存 key
    // 可以简单认为同一个设备中，三元组一样的文件，一定是同一个
    const imageId = `${name}||${size}||${lastModified}`;

    // 相同的图片已经存在，不再重复上传
    if (this.state.images[imageId]) {
      console.log('图片重复:', imageId, '全部图片：', this.state.images);
      return this.props.onError(IMG_ERR_EXIST);
    }

    // 移动端的相片有偏移，修正一次偏移
    this.readImage(image, imageId);
    this.uploadImage(image, imageId);
  }

  /**
     * 提交回复的文本内容
     * 调用外部的 props.onSubmit 实际接口
     * */
  submit() {
    const { state, props } = this;

    const images = Object.keys(state.images).map(id => state.images[id].respInfo);

    const value = this.getTextValue();

    props.onSubmit(value, images).then(resp => this.reset());
  }

  /**
     * 提交成功后，清空状态
     */
  reset() {
    this.setState({
      images: {},
    });

    this.editor.current.reset();
  }

  /**
     * 返回提交按钮是否可点
     * 当图片正在上传的时候，不能点击提交
     * */
  getButtonDisabled() {
    const { images } = this.state;

    // 判断是否有图片在上传中
    const isUploadingImage = Object.keys(images).some((id) => {
      const image = images[id];

      if (image && image.progress !== '100') {
        return true;
      }

      return false;
    });

    // 当图片还在上传过程不允许点击
    if (isUploadingImage) {
      return true;
    }

    // 字数超出的时候，禁止提交
    if (this.getLimitValueCount() < 0) {
      return true;
    }

    return false;
  }

  /**
     * 获取超出多少字数
     * */
  getLimitValueCount() {
    const { props, state } = this;

    const value = this.getTextValue();

    return Number(props.limitText - value.length);
  }

  /**
     * 获取文本内容，去掉两端空格
     */
  getTextValue() {
    if (!this.editor.current) {
      return this.props.defaultValue;
    }

    const value = this.editor.current.getValue();
    return getValue(value);
  }

  /**
     * 获取实例当前图片数据
     */
  getImageValue() {
    return this.state.images;
  }

  renderEditor() {
    const { props } = this;
    return (
      <Editor
        ref={this.editor}
        className="textarea-main auto-focus"
        placeholder={props.placeholder}
        defaultValue={revertWordToImage(props.defaultValue)}
        onKeyboardOpen={event => this.setState({ is_fold_emoji: true })}
        onPasteTransform={(string, image) => {
          // 把 [表情] 转成 <img />
          let html = revertWordToImage(string);

          // 把 \n 转成 <br />
          html = revertBr(html);

          // 把 html 字符串插入到光标处
          insertHtml(html);

          // 处理剪切板中的图片
          this.uploadImagePipe(image);
        }}
        onChange={() => this.forceUpdate()}
        onBackspace={MessageBar.backspace}
      />
    );
  }

  // 小程序做区分渲染
  renderSelectImg() {
    return (
      <Tag.div className="control-item" >
        <Tag.form className="form-wrapper-for-input-file" ref={this.formEle}>
          <BtnImageSelect
            onChange={event => this.selectImages(event)}
            // 436271 阅文集团 QQ 阅读产品不需要弹窗
            useNativePrivacy={Boolean([436271].includes(Number(this.productId)))}
          />
        </Tag.form>
      </Tag.div>
    );
  }

  renderSubmitButton() {
    return (
      <Tag.button
        className="message-bar-input__btn message-bar-input__btn--send"
        onClick={this.submit}
        disabled={this.getButtonDisabled()}
        needAuth
      >发布</Tag.button>
    );
  }


  renderTextLimit() {
    const { limitText } = this.props;

    return (
      <Tag.div className="control-item">
        <Tag.span className={classNames('notice-text', {
          'notice-text--error': this.getTextValue().length > limitText,
        })}>{this.getTextValue().length} / {limitText}</Tag.span>
      </Tag.div>
    );
  }

  renderTopic(topic) {
    if (topic?.id) {
      return <Topic topic={topic} key={topic?.id} showCancel onCancel={this.cancelTopicDetails} />;
    }
    return <EmptyTopic onClick={this.toggleFoldTopic} />;
  }

  renderView() {
    const { state, props } = this;
    const previewImages = Object.keys(state.images).map((id) => {
      const image = state.images[id];

      return {
        src: image.dataURL,
        id,
        progress: image.progress,
      };
    });
    const isReply = props.type === IMAGE_UPLOAD_TYPE.REPLY
    || props.type === IMAGE_UPLOAD_TYPE.I_WANT_REPLY
    || props.topicType === IMAGE_UPLOAD_TYPE.TOPIC_POST;
    return (
      <Tag.div aria-hidden={!props.active} catchMove className={classNames('message-bar', { active: props.active })}>
        {isReply && props.active !== 0 && <Mask className="message-bar__mask" onClick={event => props.onClose()} />}

        <Tag.div className={classNames('message-bar-input', { active: props.active, attract: !isReply })}>
          <Tag.div className="textarea-enter">

            {props.showTopic && this.renderTopic(state.topic)}

            {this.renderEditor()}

          </Tag.div>

          <ImagePreviewContainer
            images={previewImages}
            onDel={id => this.delImage(id)}
          />

          <Tag.div className="control-container">
            <Tag.div className="control-container__left">
              <Tag.div className="control-item" onClick={this.toggleFoldEmoji} {...ariaRole('button')} aria-label="表情">
                {this.state.is_fold_emoji ? <TcIconEmoji /> : <TcIconKeyboard />}
              </Tag.div>

              {state.showUploadImg && this.renderSelectImg()}

              {this.renderTextLimit()}
            </Tag.div>

            <Tag.div className="control-container__right">
              <Tag.button
                className="message-bar-input__btn message-bar-input__btn--cancel"
                onClick={() => this.setState({ showConfirmDialog: true })}
                needAuth
              >
                取消
              </Tag.button>
              {this.renderSubmitButton()}
            </Tag.div>

          </Tag.div>

          {props.Footer}
        </Tag.div>

        <EmojiKeyboard
          isFold={state.is_fold_emoji}
          onDelEmoji={() => this.editor.current.backspace()}
          onSelectEmoji={value => this.insertEmoji(value)}
        />


        {/*  评论页不需要话题 */}
        {props.type !== IMAGE_UPLOAD_TYPE.REPLY && (
          <TopicMobilePost
            topicId={props.topicId}
            topicTitle={props.topicTitle}
            isFold={state.isFoldTopic}
            onToggleFoldTopic={this.toggleFoldTopic}
            getTopicDetails={this.getTopicDetails}
          />
        )}
        {this.state.showConfirmDialog && (
          <ConfirmDialog
            onConfirm={() => Route.back()}
            onCancel={() => this.setState({ showConfirmDialog: false })}
            title="确认退出编辑？"
            subtitle="退出后不会保存你输入的内容"
            confirmText="确认退出"
          />
        )}

      </Tag.div>
    );
  }

  render() {
    const { props } = this;
    const isReply = props.type === IMAGE_UPLOAD_TYPE.REPLY || props.type === IMAGE_UPLOAD_TYPE.I_WANT_REPLY;
    const renderView = this.renderView();
    return isReply ? (
      createPortal(
        renderView
        , document.body,
      )

    ) : renderView;
  }
}

MessageBar.propTypes = {
  /**
     * 上传图片接口
     * @param {FormData} data       上传图片的表单形式
     * @param {function} onProgress 上传进度的回调函数
     * @return {Promise}
     */
  onUploadImage: PropTypes.func,
  /**
     * 占位符
     */
  placeholder: PropTypes.string,
  /**
     * 输入框初始值
     */
  defaultValue: PropTypes.string,
  /**
     * 点击发送的回调函数
     * @param value 文字内容
     * @param images 图片信息
     * @return {Promise}
     */
  onSubmit: PropTypes.func,
  /**
     * 关闭输入界面
     */
  onClose: PropTypes.func,
  /**
     * 错误处理
     * @param {Error} error 错误对象
     */
  onError: PropTypes.func,
  /**
     * 最多图片数量
     */
  limitImage: PropTypes.number,
  /**
     * 最大文字数量
     */
  limitText: PropTypes.number,
  /**
     * 是否激活界面
     * 0: 关闭界面
     * 1: 打开界面
     * 2: 打开界面且同时打开表情键盘
     */
  active: PropTypes.number,
  /**
     * 键盘区域额外内容呈现
     */
  Footer: PropTypes.element,
  /**
     * messageBar类型(对应不同使用场景)
     * reply: 回复评论
     * post: 发帖
     */
  type: PropTypes.oneOf(Object.keys(IMAGE_UPLOAD_TYPE)),
  /**
     * images的初始值
     */
  defaultImage: PropTypes.object,
  topicId: PropTypes.string,
  topicTitle: PropTypes.string,
  showTopic: PropTypes.bool,
  checkedTopic: PropTypes.func,
  /**
   * 话题官方回复
   */
  topicType: PropTypes.string,
};

MessageBar.defaultProps = {
  placeholder: '',
  defaultValue: '',
  defaultImage: {},
  type: IMAGE_UPLOAD_TYPE.REPLY,
  onSubmit: value => console.log(`未绑定 onSubmit，最终提交的内容是： ${value}`),
  onUploadImage: (data, onProgress) => console.log('未绑定 onUploadImage，上传的图片内容是：', data),
  onClose: () => console.log('未绑定 onClose'),
  onError: () => console.log('未绑定 onError'),
  limitImage: 6,
  limitText: 500,
  active: 0,
};

export { MessageBar };
